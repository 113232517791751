function persistUserToken(token: string) {
  if (token && typeof token === 'string' && token.length > 0) {
    localStorage.setItem('token', token);
  }
}

function getUserToken(): string {
  const token = localStorage.getItem('token');
  if (token && typeof token === 'string') {
    return token;
  } else {
    return '';
  }
}

export { persistUserToken, getUserToken };
