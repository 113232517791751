import React from 'react';
import { Layout } from '@arco-design/web-react';
import { FooterProps } from '@arco-design/web-react/es/Layout/interface';
import cs from 'classnames';
import styles from './style/index.module.less';

function Footer(props: FooterProps = {}) {
  const { className, ...restProps } = props;
  return (
    <Layout.Footer className={cs(styles.footer, className)} {...restProps}>
      海讯Ai后台 ，有需求可以联系
      <a target="__blank" href="https://gpt.91chat-ai.cn/kefu">
        站点客服
      </a>
    </Layout.Footer>
  );
}

export default Footer;
