import axios from 'axios';
import { getUserToken } from '@/service/user';

const token = getUserToken();
// const hxBaseUrl = 'http://127.0.0.1:8081'
const hxBaseUrl = 'https://api.haixuntech.cn';

axios.defaults.baseURL = hxBaseUrl;

let header: { [key: string]: string } = {
  'Content-Type': 'application/json',
};

if (token.length > 0) {
  header = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
}

const hxApiInstance = axios.create({
  baseURL: hxBaseUrl,
  headers: header,
});

const hxBaseRequest = {
  base_request: {
    platform_type: 'Web',
    client_version: '1.0',
    trace_id: '',
    signature: '',
    share: '',
  },
};

export { hxApiInstance, hxBaseRequest };
